import store from '@/store'
import { STORAGE_KEY } from '@/constants'

export default async (_, _1, next) => {
  const idToken = window.localStorage.getItem(STORAGE_KEY.ID_TOKEN)
  const refreshToken = window.localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN)
  const transactionId = window.sessionStorage.getItem(
    STORAGE_KEY.TRANSACTION_ID
  )

  if (idToken && refreshToken && transactionId) {
    store.commit('setTokens', { idToken, refreshToken })
    const payload = { transactionId }
    await store.dispatch('callLinkParent', payload)
    next()
  } else {
    next('/error')
  }
}
