const getPoiCheckUrlFromPayload = (payload) => {
  if (payload?.body?.poiCheckUrl) {
    return payload.body.poiCheckUrl
  }
  return ''
}

const getTransactionIdFromPayload = (payload) => {
  if (payload?.body?.transactionId) {
    return payload.body.transactionId
  }
  return ''
}

// eslint-disable-next-line import/prefer-default-export
export { getPoiCheckUrlFromPayload, getTransactionIdFromPayload }
