import store from '@/store'
import { STORAGE_KEY } from '@/constants'

export default async (_, _1, next) => {
  const idToken = window.localStorage.getItem(STORAGE_KEY.ID_TOKEN)
  const refreshToken = window.localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN)
  if (idToken && refreshToken) {
    store.commit('setTokens', { idToken, refreshToken })
  }
  next()
}
