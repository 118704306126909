import axios from 'axios'
import store from '@/store'
import { STORAGE_KEY } from '@/constants'
const API_BASE = process.env.VUE_APP_API_APPLICATIONS
const checkParent = () => {
  const existingERNParentsOnly = window.sessionStorage.getItem(
    STORAGE_KEY.EXISTING_ERN_PARENT_ONLY
  )
  const callbackUrl = `${window.location.origin}/poiComplete`
  const encodedParentIdAppCallbackUrl = btoa(callbackUrl)

  const color = findEnvColor()
  const apiPath = `${color}/v1/pid/checkParent?redirectURL=${encodedParentIdAppCallbackUrl}&existingERNParentsOnly=${existingERNParentsOnly}`
  return axios
    .get(apiPath)
    .then((response) => {
      store.commit('setCheckParentStatusCode', response.status)
      return response?.data
    })
    .catch((error) => {
      if (error.response) {
        store.commit('setCheckParentStatusCode', error.response.status)
      } else {
        store.commit('setCheckParentStatusCode', 500)
      }
      return error
    })
}
const linkParent = (data) => {
  const color = findEnvColor()
  const existingERNParentsOnly = window.sessionStorage.getItem(
    STORAGE_KEY.EXISTING_ERN_PARENT_ONLY
  )
  const apiPath = `${color}/v1/pid/linkParent?existingERNParentsOnly=${existingERNParentsOnly}`
  return axios
    .put(apiPath, { transactionId: data.transactionId })
    .then((response) => {
      store.commit('setLinkParentStatusCode', response.status)
      return response?.data
    })
    .catch((error) => {
      if (error.response) {
        store.commit('setLinkParentStatusCode', error.response.status)
      } else {
        store.commit('setLinkParentStatusCode', 500)
      }
      return error
    })
}
const findEnvColor = () => {
  const url = window.sessionStorage.getItem(STORAGE_KEY.PID_CLIENT_CALLBACK_URL)
  const full = url.replace(/(^\w+:|^)\/\//, '')
  const base = API_BASE.replace(/(^\w+:|^)\/\//, '')
  const parts = full.split('.')
  const color = parts[0]
  const ehubDomain = parts[1]
  if (ehubDomain && ehubDomain === 'ehub') return `//${color}.${base}`
  return API_BASE
}
// eslint-disable-next-line import/prefer-default-export
export { checkParent, linkParent, findEnvColor }
