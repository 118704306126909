import { isAuthenticatedRoute } from '@/utils/router'
import store from '@/store'
import { NODE_ENVIRONMENTS } from '@/constants'

// env variables are always strings, so check for string 'true' instead of boolean
const ignoreAuthGuard =
  process.env.VUE_APP_DEV_IGNORE_AUTH_GUARD === 'true' &&
  process.env.VUE_APP_ENV_NAME === NODE_ENVIRONMENTS.LOCAL

// EXAMPLE
export default (to, from, next) => {
  if (ignoreAuthGuard) {
    next()
  } else if (isAuthenticatedRoute(to) && !store.getters.isAuthenticated) {
    next('/')
  } else {
    next()
  }
}
