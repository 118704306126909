<template>
  <v-app>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import { AppBar, AppIcon, BlockUI } from '@nswdoe/doe-ui-core'
import { mapState } from 'vuex'
import { isAuthenticatedRoute } from '@/utils/router'

const App = {
  name: 'App',
  components: {
    BlockUI,
    AppBar,
    AppIcon
  },
  computed: {
    ...mapState(['isLoading']),
    isAuthedRoute() {
      return isAuthenticatedRoute(this.$route)
    }
  }
}

export default App
</script>

<style lang="scss">
@import './scss/ads.scss';
@import './scss/app.scss';

header {
  padding: 0 1rem;
}

.appbar-title h1 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.cog-btn {
  background-color: $color-secondary;
}
</style>
