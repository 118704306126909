import 'core-js/stable'
import Vue from 'vue'
import axios from 'axios'
import { IeBlocker, OAuth } from '@nswdoe/doe-ui-core'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

let renderComponent
let renderProps

if (IeBlocker.isIe()) {
  // render IeBlocker
  renderComponent = IeBlocker
  renderProps = {
    props: {
      block: true,
      appName: 'DoE UI Template'
    }
  }
} else {
  // render App
  renderComponent = App
  renderProps = {}

  // Config the App root instance (oAuth plugin, etc.)
  Vue.config.productionTip = false

  /**
   * Global event bus
   * $eventHub is used in some components such as AppBar and NavigationDrawer
   * for cross-component communication.
   * For example, in AppBar it can emit an event 'toggleNavigationDrawer'
     <v-app-bar-nav-icon
        v-if="showNavIcon && !showBackButton"
        @click.stop="$eventHub.$emit('toggleNavigationDrawer')"
        class="ml-3 mr-2"
        aria-label="Toggle navigation menu"
     />
   Then in other components you can listen to this event.
   More details on event in Vue.js: https://vuejs.org/v2/guide/events.html
   */
  Vue.prototype.$eventHub = new Vue()
}

// Authorisation request interceptor.
// Adds the Authorization header to application api (saved/submitted) requests.
axios.interceptors.request.use(
  (request) => {
    const token = store.getters.idToken
    if (token) {
      const protectedAPIs = ['/pid/']
      if (protectedAPIs.some((apiUrl) => request.url.includes(apiUrl))) {
        request.headers.Authorization = `Bearer ${token}`
      }
    }
    return request
  },
  (error) => Promise.reject(error)
)

Vue.use(OAuth, {
  router,
  pkce: true, // set to false for implicit auth
  authoriseUri: process.env.VUE_APP_SNSW_AUTH_URL,
  tokenUri: process.env.VUE_APP_SNSW_TOKEN_URL,
  forceProdMode: true, // Don't want to mock OAuth, even in dev environment

  // authorise url params
  params: {
    client_id: process.env.VUE_APP_SNSW_AUTH_CLIENT_ID,
    redirect_uri: `${window.location.origin}/login/callback/`,
    scope: 'openid profile email'
  }
})

new Vue({
  router,
  store,
  vuetify,
  created() {},
  render: (h) => h(renderComponent, renderProps)
}).$mount('#app')
