import Vue from 'vue'
import VueRouter from 'vue-router'
import authRedirectGuard from '@/router/authRedirectGuard'
import authRouterGuard from '@/router/authRouterGuard'
import checkPoiRedirectGuard from '@/router/checkPoiRedirectGuard'
import poiCompleteRedirectGuard from '@/router/poiCompleteRedirectGuard'

Vue.use(VueRouter)
const OAuthHomeView = () => import('@/views/OAuthHomeView')
const OAuthRedirectView = () => import('@/views/OAuthRedirectView')
const CheckPoiRedirectView = () => import('@/views/CheckPoiRedirectView')
const PoiCompleteRedirectView = () => import('@/views/PoiCompleteRedirectView')
const ErrorRedirectView = () => import('@/views/ErrorRedirectView')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: OAuthHomeView
  },
  {
    path: '/login/auth',
    name: 'AuthCallback',
    beforeEnter: authRedirectGuard,
    component: OAuthRedirectView
  },
  {
    path: '/checkPoi',
    meta: {
      requiresAuth: true
    },
    name: 'CheckPoi',
    beforeEnter: checkPoiRedirectGuard,
    component: CheckPoiRedirectView
  },
  {
    path: '/poiComplete',
    name: 'PoiComplete',
    beforeEnter: poiCompleteRedirectGuard,
    component: PoiCompleteRedirectView
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorRedirectView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(authRouterGuard)

export default router
