import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import authModule from '@/store/modules/auth'
import poiModule from '@/store/modules/poi'

Vue.use(Vuex)

export const config = {
  modules: {
    auth: authModule,
    poi: poiModule
  }
}

export default new Store(config)
