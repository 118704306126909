/* eslint-disable no-param-reassign, camelcase */
// eslint-disable-next-line import/no-unresolved
import { checkParent, linkParent } from 'api/poi'
import {
  getPoiCheckUrlFromPayload,
  getTransactionIdFromPayload
} from '@/utils/poi'
import { STORAGE_KEY } from '@/constants'

const poiModule = {
  state: {
    poiCheckUrl: '',
    checkParentStatusCode: '',
    linkParentStatusCode: '',
    linkParentResult: ''
  },
  mutations: {
    setPoiCheckUrl(state, value) {
      state.poiCheckUrl = value
    },
    setLinkParentStatusCode(state, value) {
      state.linkParentStatusCode = value
    },
    setCheckParentStatusCode(state, value) {
      state.checkParentStatusCode = value
    },
    setLinkParentResult(state, value) {
      state.linkParentResult = value
    }
  },
  getters: {
    poiCheckUrl: (state) => state.poiCheckUrl,
    checkParentStatusCode: (state) => state.checkParentStatusCode,
    linkParentStatusCode: (state) => state.linkParentStatusCode
  },
  actions: {
    async getPoiCheckUrl({ commit }) {
      try {
        const checkParentsResult = await checkParent()
        const transactionId = getTransactionIdFromPayload(checkParentsResult)
        window.sessionStorage.setItem(STORAGE_KEY.TRANSACTION_ID, transactionId)

        commit('setPoiCheckUrl', getPoiCheckUrlFromPayload(checkParentsResult))
        return checkParentsResult
      } catch (e) {
        return null
      }
    },
    async callLinkParent({ commit }, payload) {
      try {
        const linkedParentIdResult = await linkParent(payload)
        commit('setLinkParentResult', linkedParentIdResult)

        return linkedParentIdResult
      } catch (e) {
        return null
      }
    }
  }
}

export default poiModule
