/* eslint-disable no-param-reassign */
import jwtDecode from 'jwt-decode'

const authModule = {
  state: {
    cognitoSession: null,
    email: null,
    idToken: null,
    refreshToken: null,
    customPid: null
  },
  getters: {
    isAuthenticated: (state) => !!(state.refreshToken && state.idToken),
    idToken: (state) => state.idToken,
    isPidAvailable: (state) => !!state.customPid
  },
  mutations: {
    setCognitoSession(state, value) {
      state.cognitoSession = value
    },
    initiateAuth(state, { cognitoSession, email }) {
      state.cognitoSession = cognitoSession
      state.email = email
    },
    setTokens(state, { idToken, refreshToken }) {
      state.idToken = idToken
      state.refreshToken = refreshToken
      if (idToken) {
        const decodedJwt = jwtDecode(idToken)
        if (decodedJwt) {
          state.email = decodedJwt.email
          state.customPid = decodedJwt['custom:pid']
        }
      }
    },
    logout(state) {
      state.idToken = null
      state.refreshToken = null
      state.email = null
      state.cognitoSession = null
      state.customPid = null
    }
  }
}

export default authModule
