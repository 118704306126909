const ERROR_TYPES = {
  CHECK_PARENT: 'CHECK_PARENT',
  LINK_PARENT: 'LINK_PARENT'
}

const STORAGE_KEY = {
  TRANSACTION_ID: 'transactionId',
  PID_CLIENT_CALLBACK_URL: 'pid_client_callback_url',
  ID_TOKEN: 'id_token',
  REFRESH_TOKEN: 'refresh_token',
  OAUTH_REDIRECT_AFTER_CALLBACK: 'oauth-redirect-after-callback'
}

// eslint-disable-next-line import/prefer-default-export
export { ERROR_TYPES, STORAGE_KEY }
