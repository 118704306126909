import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import MailIcon from '@/components/icons/MailIcon'
import DocumentsIcon from '@/components/icons/DocumentsIcon'
import BusIcon from '@/components/icons/BusIcon'
import TickCircleIcon from '@/components/icons/TickCircleIcon'
import OutOfAreaIcon from '@/components/icons/OutOfAreaIcon'

import doeColors from '../settings/doe-colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      mail: {
        component: MailIcon
      },
      documents: {
        component: DocumentsIcon
      },
      bus: {
        component: BusIcon
      },
      tickCircle: {
        component: TickCircleIcon
      },
      outOfArea: {
        component: OutOfAreaIcon
      }
    }
  },
  theme: {
    themes: {
      light: {
        primary: doeColors.primary.navy,
        secondary: doeColors.primary.lightblue,
        accent: doeColors.secondary.blue1,
        success: doeColors.secondary.blue2,
        error: doeColors.notification.errorred,
        red: {
          base: doeColors.primary.red
        }
      }
    }
  }
})
